import React from 'react';
import { useLocation } from 'react-router-dom';
;

const StandartPage = () => {
  const location = useLocation();
  const companyName = location.state?.companyName || 'Company';

  return (
    <div className="standart-page">
      <h1>Welcome {companyName}</h1>
      <nav>
        <ul>
          <li><a href="/dashboard-standart">Dashboard</a></li>
          <li>Accounting
            <ul>
              <li><a href="/accounting-manager-standart">Accounting Manager</a></li>
              <li><a href="/driver-pay-standart">Driver Pay</a></li>
            </ul>
          </li>
          <li><a href="/admin-standart">Admin</a></li>
          <li>Dispatcher
            <ul>
              <li><a href="/load-manager-standart">Load Manager</a></li>
              <li><a href="/load-finder-standart">Load Finder</a></li>
            </ul>
          </li>
          <li><a href="/settings-standart">Settings</a></li>
          <li><a href="/add-new-load">Add New Load</a></li>
          <li><a href="/logout">Logout</a></li>
        </ul>
      </nav>
    </div>
  );
};

export default StandartPage;
