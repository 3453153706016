import React from 'react';
import { useLocation } from 'react-router-dom';


const PremiumPage = () => {
  const location = useLocation();
  const companyName = location.state?.companyName || 'Company';

  return (
    <div className="premium-page">
      <h1>Welcome {companyName}</h1>
      <nav>
        <ul>
          <li><a href="/dashboard-premium">Dashboard</a></li>
          <li>Accounting
            <ul>
              <li><a href="/accounting-manager-premium">Accounting Manager</a></li>
              <li><a href="/driver-pay-premium">Driver Pay</a></li>
            </ul>
          </li>
          <li><a href="/ifta-premium">IFTA</a></li>
          <li><a href="/admin-premium">Admin</a></li>
          <li>Dispatcher
            <ul>
              <li><a href="/load-manager-premium">Load Manager</a></li>
              <li><a href="/load-finder-premium">Load Finder</a></li>
            </ul>
          </li>
          <li><a href="/settings-premium">Settings</a></li>
          <li><a href="/add-new-load">Add New Load</a></li>
          <li><a href="/logout">Logout</a></li>
        </ul>
      </nav>
    </div>
  );
};

export default PremiumPage;
