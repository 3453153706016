import React, { useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from '../styles/RegisterPage.module.css'; // Importing the CSS module

const RegisterPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    companyName: '',
    companyAddress: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    mcNumber: '',  // Added MC Number
    package: location.state ? location.state.plan : 'Basic',
    password: '',
    confirmPassword: '',
    numberOfAccountants: '',
    numberOfDrivers: '',
    numberOfTrucks: ''
  });

  const [error, setError] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL || 'https://full-trucking-service-backend-1f9923292eee.herokuapp.com';

      const response = await axios.post(`${apiUrl}register`, formData, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });

      alert('Registration successful! You will be contacted for account activation.');
      navigate('/confirmation');
    } catch (error) {
      console.error('An error occurred during registration!', error);
      const errorMessage = error.response?.data?.error || 'Registration failed. Please try again.';
      setError(errorMessage);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.logo} onClick={() => navigate('/login')}>
        <span className={styles.logoPart1}>Full</span> <span className={styles.logoPart2}>Trucking</span> <span className={styles.logoPart1}>Service</span>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={styles.formRow}>
          <input
            type="text"
            name="companyName"
            placeholder="Company Name"
            value={formData.companyName}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="companyAddress"
            placeholder="Company Address"
            value={formData.companyAddress}
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.formRow}>
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.formRow}>
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="phone"
            placeholder="Phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.formRow}>
          <input
            type="text"
            name="mcNumber"
            placeholder="MC Number"
            value={formData.mcNumber}
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.formRow}>
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            required
          />
          <input
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.additionalQuestions}>
          <label>
            Number of Accountants:
            <input
              type="number"
              name="numberOfAccountants"
              value={formData.numberOfAccountants}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Number of Drivers:
            <input
              type="number"
              name="numberOfDrivers"
              value={formData.numberOfDrivers}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Number of Trucks:
            <input
              type="number"
              name="numberOfTrucks"
              value={formData.numberOfTrucks}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <div className={styles.package}>Selected Package: {formData.package}</div>
        {error && <p className={styles.errorMessage}>{error}</p>}
        <button type="submit">Register</button>
      </form>
    </div>
  );
};

export default RegisterPage;
