import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/ForgetPasswordPage.module.css'; // Importing CSS Module

const ForgetPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [mcNumber, setMcNumber] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic for sending password reset email
    navigate('/password');
  };

  return (
    <div className={styles.forgetPasswordPage}>
      <div className={styles.logo} onClick={() => navigate('/')}>
        <span className={styles.logoPart1}>Full</span> <span className={styles.logoPart2}>Trucking</span> <span className={styles.logoPart1}>Service</span>
      </div>
      <h1 className={styles.title}>Forget Password</h1>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formGroup}>
          <label>Email:</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required className={styles.input} />
        </div>
        <div className={styles.formGroup}>
          <label>MC Number:</label>
          <input type="text" value={mcNumber} onChange={(e) => setMcNumber(e.target.value)} required className={styles.input} />
        </div>
        <button type="submit" className={styles.button}>Submit</button>
      </form>
    </div>
  );
};

export default ForgetPasswordPage;
