import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../../styles/BasicPage.module.css';
import { jsPDF } from 'jspdf'; // corectare import jsPDF
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL.endsWith('/')
  ? process.env.REACT_APP_API_URL.slice(0, -1)
  : process.env.REACT_APP_API_URL;

const BasicPage = () => {
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState(null);
  const [userSettings, setUserSettings] = useState({});
  const [drivers, setDrivers] = useState([]);
  const [trucks, setTrucks] = useState([]);
  const [brokers, setBrokers] = useState([]);
  const [shippers, setShippers] = useState([]);
  const [consignees, setConsignees] = useState([]);
  const [loads, setLoads] = useState([]);
  const [driverPays, setDriverPays] = useState([]);

  // Fetch data from server using axios with cookies (JWT in cookie)
  const fetchData = async () => {
    try {
      const config = {
        withCredentials: true, // Trimitere cookie JWT la server
      };

      const [
        userSettingsRes,
        driversRes,
        trucksRes,
        brokersRes,
        shippersRes,
        consigneesRes,
        loadsRes,
        driverPaysRes,
      ] = await Promise.all([
        axios.get(`${apiUrl}/user-settings`, config),
        axios.get(`${apiUrl}/drivers`, config),
        axios.get(`${apiUrl}/trucks`, config),
        axios.get(`${apiUrl}/brokers`, config),
        axios.get(`${apiUrl}/shippers`, config),
        axios.get(`${apiUrl}/consignees`, config),
        axios.get(`${apiUrl}/loads`, config),
        axios.get(`${apiUrl}/driver-pays`, config),
      ]);

      setUserSettings(userSettingsRes.data);
      setDrivers(driversRes.data);
      setTrucks(trucksRes.data);
      setBrokers(brokersRes.data);
      setShippers(shippersRes.data);
      setConsignees(consigneesRes.data);
      setLoads(loadsRes.data);
      setDriverPays(driverPaysRes.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const openSection = (section) => {
    setActiveSection(section);
  };

  const closeSection = () => {
    setActiveSection(null);
  };

  const handleLogout = async () => {
    try {
      await axios.post(`${apiUrl}/logout`, {}, { withCredentials: true });
      navigate('/');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const updateUserSettings = async (settings) => {
    try {
      const config = {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true, // Trimiterea cookie-urilor cu cererile
      };

      const response = await axios.put(`${apiUrl}/user-settings`, settings, config);
      setUserSettings(response.data);
    } catch (error) {
      console.error('Error updating user settings:', error);
    }
  };

  const handleAddLoad = async (load) => {
    try {
      const config = {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true, // Trimiterea cookie-urilor cu cererile
      };

      const response = await axios.post(`${apiUrl}/loads`, load, config);
      setLoads([...loads, response.data]);
    } catch (error) {
      console.error('Error adding load:', error);
    }
  };

  const handleAddDriverPay = async (driverPay) => {
    try {
      const config = {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true, // Trimiterea cookie-urilor cu cererile
      };

      const response = await axios.post(`${apiUrl}/driver-pays`, driverPay, config);
      setDriverPays([...driverPays, response.data]);
    } catch (error) {
      console.error('Error adding driver pay:', error);
    }
  };

  return (
    <div className={styles.basicPage}>
      <header className={styles.header}>
        <div className={styles.logo} onClick={() => navigate('/client/basic')}>
          <span className={styles.logoPart1}>Full</span>{' '}
          <span className={styles.logoPart2}>Trucking</span>{' '}
          <span className={styles.logoPart1}>Service</span>
        </div>
        <nav className={styles.nav}>
          <ul className={styles.mainMenu}>
            <li>
              Accounting
              <ul className={styles.subMenu}>
                <li onClick={() => openSection('accounting-manager')}>Accounting Manager</li>
                <li onClick={() => openSection('driver-pay')}>Driver Pay</li>
              </ul>
            </li>
            <li>
              Admin
              <ul className={styles.subMenu}>
                <li onClick={() => openSection('drivers')}>Drivers</li>
                <li onClick={() => openSection('trucks')}>Trucks</li>
                <li onClick={() => openSection('brokers')}>Brokers</li>
                <li onClick={() => openSection('shippers')}>Shippers</li>
                <li onClick={() => openSection('consignees')}>Consignees</li>
              </ul>
            </li>
            <li>
              Dispatcher
              <ul className={styles.subMenu}>
                <li onClick={() => openSection('load-manager')}>Load Manager</li>
                <li onClick={() => openSection('load-finder')}>Load Finder</li>
              </ul>
            </li>
            <li onClick={() => openSection('settings')}>Settings</li>
            <li>
              <button
                className={styles.button}
                onClick={() => openSection('add-new-load')}
              >
                Add New Load
              </button>
            </li>
            <li>
              <button className={styles.button} onClick={handleLogout}>
                Logout
              </button>
            </li>
          </ul>
        </nav>
      </header>

      <main>
        {activeSection === 'drivers' && (
          <div className="section-container">
            <h2>Drivers</h2>
            <Drivers drivers={drivers} setDrivers={setDrivers} />
          </div>
        )}
        {activeSection === 'trucks' && (
          <div className="section-container">
            <h2>Trucks</h2>
            <Trucks trucks={trucks} setTrucks={setTrucks} />
          </div>
        )}
        {activeSection === 'brokers' && (
          <div className="section-container">
            <h2>Brokers</h2>
            <Brokers brokers={brokers} setBrokers={setBrokers} />
          </div>
        )}
        {activeSection === 'shippers' && (
          <div className="section-container">
            <h2>Shippers</h2>
            <Shippers shippers={shippers} setShippers={setShippers} />
          </div>
        )}
        {activeSection === 'consignees' && (
          <div className="section-container">
            <h2>Consignees</h2>
            <Consignees consignees={consignees} setConsignees={setConsignees} />
          </div>
        )}
        {activeSection === 'load-manager' && <LoadManager loads={loads} />}
        {activeSection === 'load-finder' && <LoadFinder loads={loads} />}
        {activeSection === 'accounting-manager' && (
          <AccountingManager driverPays={driverPays} />
        )}
        {activeSection === 'driver-pay' && (
          <DriverPay
            drivers={drivers}
            loads={loads}
            onAddDriverPay={handleAddDriverPay}
          />
        )}
        {activeSection === 'settings' && (
          <Settings settings={userSettings} updateUserSettings={updateUserSettings} />
        )}
        {activeSection === 'add-new-load' && (
          <AddLoad
            onClose={closeSection}
            onAddLoad={handleAddLoad}
            drivers={drivers}
            trucks={trucks}
            brokers={brokers}
            shippers={shippers}
            consignees={consignees}
          />
        )}
      </main>
    </div>
  );
};

// Component: Settings
const Settings = ({ settings, updateUserSettings }) => {
  return (
    <div>
      <h2>Settings</h2>
      <p>User settings: {JSON.stringify(settings)}</p>
      <button onClick={() => updateUserSettings({ someSetting: 'newValue' })}>
        Update Settings
      </button>
    </div>
  );
};

// Component: Drivers
const Drivers = ({ drivers, setDrivers }) => {
  const [driverData, setDriverData] = useState({
    firstName: '',
    lastName: '',
    truckNumber: '',
    email: '',
    phone: '',
    address: '',
    licenseNumber: '',
    licenseExpiryDate: '',
    lastDrugTestDate: '',
    settlement: '',
    notes: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDriverData({ ...driverData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${apiUrl}/drivers`, driverData, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      setDrivers([...drivers, response.data]);
      setDriverData({
        firstName: '',
        lastName: '',
        truckNumber: '',
        email: '',
        phone: '',
        address: '',
        licenseNumber: '',
        licenseExpiryDate: '',
        lastDrugTestDate: '',
        settlement: '',
        notes: '',
      });
    } catch (error) {
      console.error('Error adding driver:', error);
      alert('Error adding driver, please try again.');  // Mesaj pentru utilizator
    }
  };

  return (
    <div className="drivers-section">
      <h3>Add New Driver</h3>
      <form>
        <label>First Name*</label>
        <input
          name="firstName"
          value={driverData.firstName}
          onChange={handleChange}
          placeholder="First Name"
          required
        />
        <label>Last Name*</label>
        <input
          name="lastName"
          value={driverData.lastName}
          onChange={handleChange}
          placeholder="Last Name"
          required
        />
        <label>Truck Number*</label>
        <input
          name="truckNumber"
          value={driverData.truckNumber}
          onChange={handleChange}
          placeholder="Truck Number"
          required
        />
        <label>Email*</label>
        <input
          name="email"
          value={driverData.email}
          onChange={handleChange}
          placeholder="Email"
          required
        />
        <label>Phone*</label>
        <input
          name="phone"
          value={driverData.phone}
          onChange={handleChange}
          placeholder="Phone"
          required
        />
        <label>Address*</label>
        <input
          name="address"
          value={driverData.address}
          onChange={handleChange}
          placeholder="Address"
          required
        />
        <label>License Number*</label>
        <input
          name="licenseNumber"
          value={driverData.licenseNumber}
          onChange={handleChange}
          placeholder="License Number"
          required
        />
        <label>License Expiry Date*</label>
        <input
          name="licenseExpiryDate"
          type="date"
          value={driverData.licenseExpiryDate}
          onChange={handleChange}
          placeholder="License Expiry Date"
          required
        />
        <label>Last Drug Test Date*</label>
        <input
          name="lastDrugTestDate"
          type="date"
          value={driverData.lastDrugTestDate}
          onChange={handleChange}
          placeholder="Last Drug Test Date"
          required
        />
        <label>Settlement*</label>
        <select
          name="settlement"
          value={driverData.settlement}
          onChange={handleChange}
          required
        >
          <option value="">Select Payment Method</option>
          <option value="Hourly pay">Hourly pay</option>
          <option value="Per mile pay">Per mile pay</option>
          <option value="Fixed salary">Fixed salary</option>
          <option value="Percentage of the load">Percentage of the load</option>
          <option value="Team driver pay">Team driver pay</option>
        </select>
        <label>Notes</label>
        <textarea
          name="notes"
          value={driverData.notes}
          onChange={handleChange}
          placeholder="Notes"
        ></textarea>
        <button type="button" onClick={handleSubmit}>
          Add Driver
        </button>
      </form>

      <h3>Existing Drivers</h3>
      <ul>
        {drivers.map((driver, index) => (
          <li key={index}>
            {driver.firstName} {driver.lastName}
          </li>
        ))}
      </ul>
    </div>
  );
};

// Component: Trucks
const Trucks = ({ trucks, setTrucks }) => {
  const [truckData, setTruckData] = useState({
    truckNumber: '',
    miles: '',
    licenses: '',
    settlement: '',
    notes: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTruckData({ ...truckData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${apiUrl}/trucks`, truckData, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      setTrucks([...trucks, response.data]);
      setTruckData({
        truckNumber: '',
        miles: '',
        licenses: '',
        settlement: '',
        notes: '',
      });
    } catch (error) {
      console.error('Error adding truck:', error);
    }
  };

  return (
    <div className="trucks-section">
      <h3>Add New Truck</h3>
      <form>
        <label>Truck Number*</label>
        <input
          name="truckNumber"
          value={truckData.truckNumber}
          onChange={handleChange}
          placeholder="Truck Number"
          required
        />
        <label>Miles*</label>
        <input
          name="miles"
          value={truckData.miles}
          onChange={handleChange}
          placeholder="Miles"
          required
        />
        <label>Licenses*</label>
        <input
          name="licenses"
          value={truckData.licenses}
          onChange={handleChange}
          placeholder="Licenses"
          required
        />
        <label>Settlement*</label>
        <input
          name="settlement"
          value={truckData.settlement}
          onChange={handleChange}
          placeholder="Settlement"
          required
        />
        <label>Notes</label>
        <textarea
          name="notes"
          value={truckData.notes}
          onChange={handleChange}
          placeholder="Notes"
        ></textarea>
        <button type="button" onClick={handleSubmit}>
          Add Truck
        </button>
      </form>

      <h3>Existing Trucks</h3>
      <ul>
        {trucks.map((truck, index) => (
          <li key={index}>{truck.truckNumber}</li>
        ))}
      </ul>
    </div>
  );
};

// Component: Brokers
const Brokers = ({ brokers, setBrokers }) => {
  const [brokerData, setBrokerData] = useState({
    brokerName: '',
    address: '',
    email: '',
    phone: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBrokerData({ ...brokerData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${apiUrl}/brokers`, brokerData, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      setBrokers([...brokers, response.data]);
      setBrokerData({
        brokerName: '',
        address: '',
        email: '',
        phone: '',
      });
    } catch (error) {
      console.error('Error adding broker:', error);
    }
  };

  return (
    <div className="brokers-section">
      <h3>Add New Broker</h3>
      <form>
        <label>Broker Name*</label>
        <input
          name="brokerName"
          value={brokerData.brokerName}
          onChange={handleChange}
          placeholder="Broker Name"
          required
        />
        <label>Address*</label>
        <input
          name="address"
          value={brokerData.address}
          onChange={handleChange}
          placeholder="Address"
          required
        />
        <label>Email*</label>
        <input
          name="email"
          value={brokerData.email}
          onChange={handleChange}
          placeholder="Email"
          required
        />
        <label>Phone*</label>
        <input
          name="phone"
          value={brokerData.phone}
          onChange={handleChange}
          placeholder="Phone"
          required
        />
        <button type="button" onClick={handleSubmit}>
          Add Broker
        </button>
      </form>

      <h3>Existing Brokers</h3>
      <ul>
        {brokers.map((broker, index) => (
          <li key={index}>{broker.brokerName}</li>
        ))}
      </ul>
    </div>
  );
};

// Component: Shippers
const Shippers = ({ shippers, setShippers }) => {
  const [shipperData, setShipperData] = useState({
    shipperName: '',
    address: '',
    email: '',
    phone: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setShipperData({ ...shipperData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${apiUrl}/shippers`, shipperData, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      setShippers([...shippers, response.data]);
      setShipperData({
        shipperName: '',
        address: '',
        email: '',
        phone: '',
      });
    } catch (error) {
      console.error('Error adding shipper:', error);
    }
  };

  return (
    <div className="shippers-section">
      <h3>Add New Shipper</h3>
      <form>
        <label>Shipper Name*</label>
        <input
          name="shipperName"
          value={shipperData.shipperName}
          onChange={handleChange}
          placeholder="Shipper Name"
          required
        />
        <label>Address*</label>
        <input
          name="address"
          value={shipperData.address}
          onChange={handleChange}
          placeholder="Address"
          required
        />
        <label>Email*</label>
        <input
          name="email"
          value={shipperData.email}
          onChange={handleChange}
          placeholder="Email"
          required
        />
        <label>Phone*</label>
        <input
          name="phone"
          value={shipperData.phone}
          onChange={handleChange}
          placeholder="Phone"
          required
        />
        <button type="button" onClick={handleSubmit}>
          Add Shipper
        </button>
      </form>

      <h3>Existing Shippers</h3>
      <ul>
        {shippers.map((shipper, index) => (
          <li key={index}>{shipper.shipperName}</li>
        ))}
      </ul>
    </div>
  );
};

// Component: Consignees
const Consignees = ({ consignees, setConsignees }) => {
  const [consigneeData, setConsigneeData] = useState({
    consigneeName: '',
    address: '',
    email: '',
    phone: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setConsigneeData({ ...consigneeData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${apiUrl}/consignees`, consigneeData, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      setConsignees([...consignees, response.data]);
      setConsigneeData({
        consigneeName: '',
        address: '',
        email: '',
        phone: '',
      });
    } catch (error) {
      console.error('Error adding consignee:', error);
    }
  };

  return (
    <div className="consignees-section">
      <h3>Add New Consignee</h3>
      <form>
        <label>Consignee Name*</label>
        <input
          name="consigneeName"
          value={consigneeData.consigneeName}
          onChange={handleChange}
          placeholder="Consignee Name"
          required
        />
        <label>Address*</label>
        <input
          name="address"
          value={consigneeData.address}
          onChange={handleChange}
          placeholder="Address"
          required
        />
        <label>Email*</label>
        <input
          name="email"
          value={consigneeData.email}
          onChange={handleChange}
          placeholder="Email"
          required
        />
        <label>Phone*</label>
        <input
          name="phone"
          value={consigneeData.phone}
          onChange={handleChange}
          placeholder="Phone"
          required
        />
        <button type="button" onClick={handleSubmit}>
          Add Consignee
        </button>
      </form>

      <h3>Existing Consignees</h3>
      <ul>
        {consignees.map((consignee, index) => (
          <li key={index}>{consignee.consigneeName}</li>
        ))}
      </ul>
    </div>
  );
};

// Component: LoadManager
const LoadManager = ({ loads }) => {
  const [editingLoadIndex, setEditingLoadIndex] = useState(null);
  const [editingLoad, setEditingLoad] = useState(null);

  const handleEditLoad = (index) => {
    setEditingLoadIndex(index);
    setEditingLoad(loads[index]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditingLoad({ ...editingLoad, [name]: value });
  };

  const handleSave = async () => {
    try {
      await axios.put(`${apiUrl}/loads/${editingLoad._id}`, editingLoad, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      const updatedLoads = [...loads];
      updatedLoads[editingLoadIndex] = editingLoad;
      setEditingLoadIndex(null);
      setEditingLoad(null);
    } catch (error) {
      console.error('Error updating load:', error);
    }
  };

  return (
    <div className="load-manager-section">
      <h2>Load Manager</h2>
      <ul>
        {loads.map((load, index) => (
          <li key={index}>
            {editingLoadIndex === index ? (
              <div>
                <label>Load #:</label>
                <input
                  name="loadNumber"
                  value={editingLoad.loadNumber}
                  onChange={handleChange}
                />
                <label>Dispatcher:</label>
                <input
                  name="dispatcher"
                  value={editingLoad.dispatcher}
                  onChange={handleChange}
                />
                <label>Broker Name:</label>
                <input
                  name="brokerName"
                  value={editingLoad.brokerName}
                  onChange={handleChange}
                />
                <label>Rate:</label>
                <input name="rate" value={editingLoad.rate} onChange={handleChange} />
                <label>Driver:</label>
                <input
                  name="driver"
                  value={editingLoad.driver}
                  onChange={handleChange}
                />
                <label>Miles:</label>
                <input
                  name="miles"
                  value={editingLoad.miles}
                  onChange={handleChange}
                />
                <label>DH:</label>
                <input name="dh" value={editingLoad.dh} onChange={handleChange} />
                <button onClick={handleSave}>Save</button>
              </div>
            ) : (
              <div>
                <strong>Load #:</strong> {load.loadNumber} <br />
                <strong>Dispatcher:</strong> {load.dispatcher} <br />
                <strong>Broker Name:</strong> {load.brokerName} <br />
                <strong>Rate:</strong> ${load.rate} <br />
                <strong>Driver:</strong> {load.driver} <br />
                <strong>Miles:</strong> {load.miles} <br />
                <strong>DH:</strong> {load.dh} <br />
                <button onClick={() => handleEditLoad(index)}>Edit</button>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

// Component: LoadFinder
const LoadFinder = ({ loads }) => {
  const [search, setSearch] = useState('');
  const filteredLoads = loads.filter((load) =>
    load.loadNumber.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="load-finder-section">
      <h2>Load Finder</h2>
      <input
        type="text"
        placeholder="Search by Load Number"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <ul>
        {filteredLoads.map((load, index) => (
          <li key={index}>
            <strong>Load #:</strong> {load.loadNumber} <br />
            <strong>Dispatcher:</strong> {load.dispatcher} <br />
            <strong>Broker Name:</strong> {load.brokerName} <br />
            <strong>Rate:</strong> ${load.rate} <br />
            <strong>Driver:</strong> {load.driver} <br />
            <strong>Miles:</strong> {load.miles} <br />
            <strong>DH:</strong> {load.dh} <br />
          </li>
        ))}
      </ul>
    </div>
  );
};

// Component: AccountingManager
const AccountingManager = ({ driverPays }) => {
  const [selectedDriverPayIndex, setSelectedDriverPayIndex] = useState(null);
  const [selectedDriverPay, setSelectedDriverPay] = useState(null);

  const handleEditDriverPay = (index) => {
    setSelectedDriverPayIndex(index);
    setSelectedDriverPay(driverPays[index]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedDriverPay({ ...selectedDriverPay, [name]: value });
  };

  const handleSave = async () => {
    try {
      await axios.put(
        `${apiUrl}/driver-pays/${selectedDriverPay._id}`,
        selectedDriverPay,
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      );
      const updatedDriverPays = [...driverPays];
      updatedDriverPays[selectedDriverPayIndex] = selectedDriverPay;
      setSelectedDriverPayIndex(null);
      setSelectedDriverPay(null);
    } catch (error) {
      console.error('Error updating driver pay:', error);
    }
  };

  return (
    <div className="accounting-manager-section">
      <h2>Accounting Manager</h2>
      <ul>
        {driverPays.map((pay, index) => (
          <li key={index}>
            {selectedDriverPayIndex === index ? (
              <div>
                <label>Driver:</label>
                <input
                  name="driverName"
                  value={selectedDriverPay.driverName}
                  onChange={handleChange}
                />
                <label>Total Pay:</label>
                <input
                  name="totalPay"
                  value={selectedDriverPay.totalPay}
                  onChange={handleChange}
                />
                <button onClick={handleSave}>Save</button>
              </div>
            ) : (
              <div>
                <strong>Driver:</strong> {pay.driverName} <br />
                <strong>Total Pay:</strong> ${pay.totalPay.toFixed(2)} <br />
                <button onClick={() => handleEditDriverPay(index)}>Edit</button>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

// Component: DriverPay
const DriverPay = ({ drivers, loads, onAddDriverPay }) => {
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filteredLoads, setFilteredLoads] = useState([]);
  const [recurringDeduction, setRecurringDeduction] = useState({
    amount: 0,
    service: '',
  });
  const [recurringAddition, setRecurringAddition] = useState({
    amount: 0,
    service: '',
  });

  const handleDriverSelect = (driver) => {
    setSelectedDriver(driver);
    setFilteredLoads([]);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'startDate') {
      setStartDate(value);
    } else {
      setEndDate(value);
    }
  };

  const searchLoads = () => {
    if (!selectedDriver || !startDate || !endDate) return;
    const filtered = loads.filter(
      (load) =>
        load.driver === `${selectedDriver.firstName} ${selectedDriver.lastName}` &&
        new Date(load.shippers[0].pickUpTime) >= new Date(startDate) &&
        new Date(load.consignees[load.consignees.length - 1].deliveryTime) <=
          new Date(endDate)
    );
    setFilteredLoads(filtered);
  };

  const calculatePay = () => {
    const payDetails = filteredLoads.map((load) => {
      const rate = parseFloat(load.rate);
      let pay = 0;

      if (selectedDriver.settlement === 'Percentage of the load') {
        const percentage = parseFloat(selectedDriver.percentage) / 100;
        pay = rate * percentage;
      } else if (selectedDriver.settlement === 'Per mile pay') {
        const miles = parseFloat(load.miles);
        const ratePerMile = parseFloat(selectedDriver.ratePerMile);
        pay = miles * ratePerMile;
      }

      return {
        loadNumber: load.loadNumber,
        pickUpTime: load.shippers[0].pickUpTime,
        deliveryTime: load.consignees[load.consignees.length - 1].deliveryTime,
        rate,
        pay,
      };
    });

    const totalPay =
      payDetails.reduce((sum, detail) => sum + detail.pay, 0) +
      parseFloat(recurringAddition.amount) -
      parseFloat(recurringDeduction.amount);

    return {
      payDetails,
      totalPay,
      recurringAddition,
      recurringDeduction,
    };
  };

  const generatePDF = () => {
    const { payDetails, totalPay, recurringAddition, recurringDeduction } =
      calculatePay();

    const doc = new jsPDF();
    doc.text('Driver Pay Details', 10, 10);
    doc.text(`Driver: ${selectedDriver.firstName} ${selectedDriver.lastName}`, 10, 20);
    doc.text(`Start Date: ${startDate}`, 10, 30);
    doc.text(`End Date: ${endDate}`, 10, 40);

    let yPosition = 50;
    payDetails.forEach((detail) => {
      doc.text(`Load #: ${detail.loadNumber}`, 10, yPosition);
      doc.text(`Pick Up: ${detail.pickUpTime}`, 10, yPosition + 10);
      doc.text(`Delivery: ${detail.deliveryTime}`, 10, yPosition + 20);
      doc.text(`Rate: $${detail.rate.toFixed(2)}`, 10, yPosition + 30);
      doc.text(`Pay: $${detail.pay.toFixed(2)}`, 10, yPosition + 40);
      yPosition += 50;
    });

    doc.text(
      `Recurring Addition (${recurringAddition.service}): $${parseFloat(
        recurringAddition.amount
      ).toFixed(2)}`,
      10,
      yPosition
    );
    yPosition += 10;
    doc.text(
      `Recurring Deduction (${recurringDeduction.service}): $${parseFloat(
        recurringDeduction.amount
      ).toFixed(2)}`,
      10,
      yPosition
    );
    yPosition += 10;
    doc.text(`Total Pay: $${totalPay.toFixed(2)}`, 10, yPosition + 20);

    doc.save('driver_pay.pdf');

    onAddDriverPay({
      driverName: `${selectedDriver.firstName} ${selectedDriver.lastName}`,
      totalPay,
      payDetails,
      recurringAddition,
      recurringDeduction,
    });
  };

  return (
    <div className="driver-pay-section">
      <h2>Driver Pay</h2>
      <div>
        <label>Select Driver</label>
        <select onChange={(e) => handleDriverSelect(drivers[e.target.value])}>
          <option value="">Select Driver</option>
          {drivers.map((driver, index) => (
            <option key={index} value={index}>
              {driver.firstName} {driver.lastName}
            </option>
          ))}
        </select>
      </div>
      {selectedDriver && (
        <div>
          <h3>
            Driver: {selectedDriver.firstName} {selectedDriver.lastName}
          </h3>
          <div>
            <label>Start Date</label>
            <input
              type="date"
              name="startDate"
              value={startDate}
              onChange={handleDateChange}
            />
            <label>End Date</label>
            <input
              type="date"
              name="endDate"
              value={endDate}
              onChange={handleDateChange}
            />
          </div>
          <button onClick={searchLoads}>Search</button>
          {filteredLoads.length > 0 && (
            <div>
              <h3>Load Details</h3>
              <ul>
                {filteredLoads.map((load, index) => (
                  <li key={index}>
                    <input type="checkbox" value={load.loadNumber} />
                    <strong>Load #:</strong> {load.loadNumber} <br />
                    <strong>Pick Up:</strong> {load.shippers[0].pickUpTime} <br />
                    <strong>Delivery:</strong>{' '}
                    {load.consignees[load.consignees.length - 1].deliveryTime} <br />
                    <strong>Rate:</strong> ${parseFloat(load.rate).toFixed(2)}
                  </li>
                ))}
              </ul>
              <button onClick={calculatePay}>Calculate</button>
              <div>
                <label>Recurring Deduction</label>
                <input
                  type="number"
                  value={recurringDeduction.amount}
                  onChange={(e) =>
                    setRecurringDeduction({
                      ...recurringDeduction,
                      amount: e.target.value,
                    })
                  }
                  placeholder="Amount"
                />
                <input
                  type="text"
                  value={recurringDeduction.service}
                  onChange={(e) =>
                    setRecurringDeduction({
                      ...recurringDeduction,
                      service: e.target.value,
                    })
                  }
                  placeholder="Service"
                />
                <label>Recurring Addition</label>
                <input
                  type="number"
                  value={recurringAddition.amount}
                  onChange={(e) =>
                    setRecurringAddition({
                      ...recurringAddition,
                      amount: e.target.value,
                    })
                  }
                  placeholder="Amount"
                />
                <input
                  type="text"
                  value={recurringAddition.service}
                  onChange={(e) =>
                    setRecurringAddition({
                      ...recurringAddition,
                      service: e.target.value,
                    })
                  }
                  placeholder="Service"
                />
              </div>
              <button onClick={generatePDF}>Save and Preview</button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

// Component: AddLoad
const AddLoad = ({
  onClose,
  onAddLoad,
  drivers,
  trucks,
  brokers,
  shippers,
  consignees,
}) => {
  const [loadData, setLoadData] = useState({
    loadNumber: '',
    dispatcher: '',
    brokerName: '',
    brokerAddress: '',
    rate: '',
    equipment: '',
    driver: '',
    truck: '',
    status: '',
    shippers: [{ name: '', address: '', pickUpTime: '' }],
    consignees: [{ name: '', address: '', deliveryTime: '' }],
    miles: '',
    dh: '',
    notes: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoadData({ ...loadData, [name]: value });
  };

  const handleShipperChange = (index, e) => {
    const { name, value } = e.target;
    const newShippers = [...loadData.shippers];
    newShippers[index][name] = value;
    setLoadData({ ...loadData, shippers: newShippers });
  };

  const handleConsigneeChange = (index, e) => {
    const { name, value } = e.target;
    const newConsignees = [...loadData.consignees];
    newConsignees[index][name] = value;
    setLoadData({ ...loadData, consignees: newConsignees });
  };

  const addShipper = () => {
    setLoadData({
      ...loadData,
      shippers: [...loadData.shippers, { name: '', address: '', pickUpTime: '' }],
    });
  };

  const addConsignee = () => {
    setLoadData({
      ...loadData,
      consignees: [
        ...loadData.consignees,
        { name: '', address: '', deliveryTime: '' },
      ],
    });
  };

  const handleSubmit = () => {
    if (
      !loadData.loadNumber ||
      !loadData.brokerName ||
      !loadData.brokerAddress ||
      !loadData.driver ||
      !loadData.status ||
      !loadData.shippers[0].name ||
      !loadData.consignees[0].name
    ) {
      alert('Please fill in all required fields');
      return;
    }
    onAddLoad(loadData);
    setLoadData({
      loadNumber: '',
      dispatcher: '',
      brokerName: '',
      brokerAddress: '',
      rate: '',
      equipment: '',
      driver: '',
      truck: '',
      status: '',
      shippers: [{ name: '', address: '', pickUpTime: '' }],
      consignees: [{ name: '', address: '', deliveryTime: '' }],
      miles: '',
      dh: '',
      notes: '',
    });
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <div className="section-container">
      <button className="close-btn" onClick={handleCancel}>
        Cancel
      </button>
      <h2>Add New Load</h2>
      <form>
        <label>Load Number*</label>
        <input
          name="loadNumber"
          value={loadData.loadNumber}
          onChange={handleChange}
          placeholder="Load Number"
          required
        />
        <label>Dispatcher*</label>
        <input
          name="dispatcher"
          value={loadData.dispatcher}
          onChange={handleChange}
          placeholder="Dispatcher"
          required
        />
        <label>Broker Name*</label>
        <input
          name="brokerName"
          value={loadData.brokerName}
          onChange={handleChange}
          placeholder="Broker Name"
          required
        />
        <label>Broker Address*</label>
        <input
          name="brokerAddress"
          value={loadData.brokerAddress}
          onChange={handleChange}
          placeholder="Broker Address"
          required
        />
        <label>Rate (in dollars)*</label>
        <input
          name="rate"
          value={loadData.rate}
          onChange={handleChange}
          placeholder="Rate"
          required
        />
        <label>Equipment</label>
        <select
          name="equipment"
          value={loadData.equipment}
          onChange={handleChange}
        >
          <option value="">Select Equipment</option>
          <option value="Dry Van">Dry Van</option>
          <option value="53 Van">53 Van</option>
          <option value="Reefer">Reefer</option>
          <option value="Flat Bed">Flat Bed</option>
          <option value="Low Boy">Low Boy</option>
          <option value="Tanker">Tanker</option>
          <option value="Container Hauler">Container Hauler</option>
        </select>
        <label>Driver*</label>
        <select
          name="driver"
          value={loadData.driver}
          onChange={handleChange}
          required
        >
          <option value="">Select Driver</option>
          {drivers.map((driver, index) => (
            <option key={index} value={driver._id}>
              {driver.firstName} {driver.lastName}
            </option>
          ))}
        </select>
        <label>Truck</label>
        <select name="truck" value={loadData.truck} onChange={handleChange}>
          <option value="">Select Truck</option>
          {trucks.map((truck, index) => (
            <option key={index} value={truck._id}>
              {truck.truckNumber}
            </option>
          ))}
        </select>

        <label>Status*</label>
        <select
          name="status"
          value={loadData.status}
          onChange={handleChange}
          required
        >
          <option value="">Select Status</option>
          <option value="In Progress">In Progress</option>
          <option value="Completed">Completed</option>
          <option value="Cancelled">Cancelled</option>
        </select>

        <label>Shippers*</label>
        {loadData.shippers.map((shipper, index) => (
          <div key={index}>
            <input
              name="name"
              value={shipper.name}
              onChange={(e) => handleShipperChange(index, e)}
              placeholder="Shipper Name"
              required
            />
            <input
              name="address"
              value={shipper.address}
              onChange={(e) => handleShipperChange(index, e)}
              placeholder="Shipper Address"
              required
            />
            <input
              name="pickUpTime"
              type="datetime-local"
              value={shipper.pickUpTime}
              onChange={(e) => handleShipperChange(index, e)}
              required
            />
            {index === loadData.shippers.length - 1 && (
              <button type="button" onClick={addShipper}>
                +
              </button>
            )}
          </div>
        ))}

        <label>Consignees*</label>
        {loadData.consignees.map((consignee, index) => (
          <div key={index}>
            <input
              name="name"
              value={consignee.name}
              onChange={(e) => handleConsigneeChange(index, e)}
              placeholder="Consignee Name"
              required
            />
            <input
              name="address"
              value={consignee.address}
              onChange={(e) => handleConsigneeChange(index, e)}
              placeholder="Consignee Address"
              required
            />
            <input
              name="deliveryTime"
              type="datetime-local"
              value={consignee.deliveryTime}
              onChange={(e) => handleConsigneeChange(index, e)}
              required
            />
            {index === loadData.consignees.length - 1 && (
              <button type="button" onClick={addConsignee}>
                +
              </button>
            )}
          </div>
        ))}

        <label>Miles</label>
        <input
          name="miles"
          value={loadData.miles}
          onChange={handleChange}
          placeholder="Miles"
          readOnly
        />
        <label>DH</label>
        <input name="dh" value={loadData.dh} onChange={handleChange} placeholder="DH" readOnly />

        <label>Notes</label>
        <textarea
          name="notes"
          value={loadData.notes}
          onChange={handleChange}
          placeholder="Notes"
        ></textarea>

        <button type="button" onClick={handleSubmit}>
          Add New Load
        </button>
        <button type="button" onClick={handleCancel}>
          Cancel
        </button>
      </form>
    </div>
  );
};

export default BasicPage;
