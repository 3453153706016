import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/PricingPage.module.css'; // Notice the import from CSS module

const PricingPage = () => {
  const navigate = useNavigate();

  const handleSelectPlan = (plan) => {
    navigate('/register', { state: { plan } });
  };

  return (
    <div className={styles.pricingPage}>
      <div className={styles.logo} onClick={() => navigate('/login')}>
        <span className={styles.logoPart1}>Full</span> <span className={styles.logoPart2}>Trucking</span> <span className={styles.logoPart1}>Service</span>
      </div>
      <h1 className={styles.title}>Choose your package</h1>
      <div className={styles.plans}>
        <div className={styles.plan} onClick={() => handleSelectPlan('Basic')}>
          <h2>Basic</h2>
          <p>All you need for a small company</p>
          <ul>
            <li>Add Loads, Drivers, Brokers, etc.</li>
            <li>Manage Driver Pay and download reports</li>
          </ul>
          <div className={styles.price}>Monthly: €39.99</div>
          <div className={styles.priceAnnual}>Annually: €29.99/month</div>
          <button>Select Basic</button>
        </div>

        <div className={styles.plan} onClick={() => handleSelectPlan('Standard')}>
          <h2>Standard</h2>
          <p>Includes everything from Basic plus more</p>
          <ul>
            <li>Add your company logo to Driver Pay PDFs</li>
            <li>Add an extra accountant</li>
          </ul>
          <div className={styles.price}>Monthly: €59.99</div>
          <div className={styles.priceAnnual}>Annually: €49.99/month (1 month free)</div>
          <button>Select Standard</button>
        </div>

        <div className={styles.plan} onClick={() => handleSelectPlan('Premium')}>
          <h2>Premium</h2>
          <p>Includes everything from Standard plus IFTA management</p>
          <ul>
            <li>Upload PDF reconfirmations directly</li>
            <li>Get 3 additional accountants</li>
          </ul>
          <div className={styles.price}>Monthly: €79.99</div>
          <div className={styles.priceAnnual}>Annually: €69.99/month (2 months free)</div>
          <button>Select Premium</button>
        </div>

        <div className={styles.plan} onClick={() => handleSelectPlan('Ultimate')}>
          <h2>Ultimate</h2>
          <p>Everything from Premium plus mobile app for drivers</p>
          <ul>
            <li>Communicate via chat, share documents</li>
            <li>Track and share location with shippers</li>
          </ul>
          <div className={styles.price}>Monthly: €99.99</div>
          <div className={styles.priceAnnual}>Annually: €89.99/month (3 months free)</div>
          <button>Select Ultimate</button>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
