import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/ConfirmationPage.module.css'; // Make sure the path is correct

const ConfirmationPage = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.confirmationPage}>
      <div className={styles.logo} onClick={() => navigate('/')}>
        <span className={styles.logoPart1}>Full</span> <span className={styles.logoPart2}>Trucking</span> <span className={styles.logoPart1}>Service</span>
      </div>
      <h1>Thank you for registering!</h1>
      <p>We will contact you shortly to activate your account.</p>
      <button onClick={() => navigate('/')}>Go back to Login</button>
    </div>
  );
};

export default ConfirmationPage;
