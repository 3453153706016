import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from '../styles/LoginPage.module.css'; // Import the CSS module

const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Reset error message on each login attempt
  
    try {
      const apiUrl = process.env.REACT_APP_API_URL || 'https://full-trucking-service-backend-1f9923292eee.herokuapp.com/';
  
      console.log('Email:', email);
      console.log('Parola trimisă:', password);
  
      const response = await axios.post(
        `${apiUrl}login`, 
        { email, password }, 
        { 
          withCredentials: true, 
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (response.data.message === 'Login successful') {
        switch (response.data.package) {
          case 'Basic':
            navigate('/client/basic');
            break;
          case 'Standard':
            navigate('/client/standard');
            break;
          case 'Premium':
            navigate('/client/premium');
            break;
          case 'Ultimate':
            navigate('/client/ultimate');
            break;
          default:
            setError('Invalid package');
        }
      } else {
        setError('Invalid email or password');
      }
    } catch (err) {
      console.error('Login error:', err);
      const errorMessage = err.response?.data?.error || 'An error occurred. Please try again.';
      setError(errorMessage);
    }
  };
  

  return (
    <div className={styles.loginPage}>
      {/* Logo with onClick */}
      <div className={styles.logo} onClick={() => navigate('/')}>
        <span className={styles.logoPart1}>Full</span> <span className={styles.logoPart2}>Trucking</span> <span className={styles.logoPart1}>Service</span>
      </div>
      <h1 className={styles.title}>Log in</h1>
      <form onSubmit={handleLogin} className={styles.form}>
        <input 
          type="email" 
          placeholder="Email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          required 
          className={styles.input}
        />
        <input 
          type="password" 
          placeholder="Password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          required 
          className={styles.input}
        />
        {error && <p className={styles.errorMessage}>{error}</p>}
        <button type="submit" className={styles.button}>Login</button>
      </form>
      <div className={styles.links}>
        <div onClick={() => navigate('/pricing')} className={styles.buttonLink}>Register</div>
        <div onClick={() => navigate('/forget-password')} className={styles.buttonLink}>Forget Password</div>
      </div>
    </div>
  );
};

export default LoginPage;
