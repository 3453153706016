import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './components/pages/LoginPage';
import PricingPage from './components/pages/PricingPage';
import RegisterPage from './components/pages/RegisterPage';
import ForgetPasswordPage from './components/pages/ForgetPasswordPage';
import BasicPage from './components/pages/client/BasicPage';
import StandartPage from './components/pages/client/StandartPage';
import PremiumPage from './components/pages/client/PremiumPage';
import UltimatePage from './components/pages/client/UltimatePage';
import ConfirmationPage from './components/pages/ConfirmationPage';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} /> {/* Redirect către pagina de Frontend Login */}
      <Route path="/login" element={<LoginPage />} />
      <Route path="/pricing" element={<PricingPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/confirmation" element={<ConfirmationPage />} />
      <Route path="/forget-password" element={<ForgetPasswordPage />} />
      <Route path="/client/basic/*" element={<BasicPage />} />
      <Route path="/client/standart/*" element={<StandartPage />} />
      <Route path="/client/premium/*" element={<PremiumPage />} />
      <Route path="/client/ultimate/*" element={<UltimatePage />} />
    </Routes>
  );
};

export default App;